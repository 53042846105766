import { DateTime } from 'luxon';

export class DateUtils {
  static toJSDate(input: string | Date | null | undefined): Date | undefined {
    if (input === null || input === undefined) {
      return undefined;
    }

    const browserTimezone = DateTime.local().zoneName;

    if (input instanceof Date) {
      return isNaN(input.getTime()) ? undefined : input;
    }

    if (typeof input === 'string') {
      // Attempt to parse as an ISO string
      const dateTimeISO = DateTime.fromISO(input, { zone: browserTimezone });
      if (dateTimeISO.isValid) {
        return dateTimeISO.toJSDate();
      }

      // Attempt to parse as a common date string format
      const dateTimeFromFormat = DateTime.fromFormat(input, 'yyyy-MM-dd', {
        zone: browserTimezone,
      });
      if (dateTimeFromFormat.isValid) {
        return dateTimeFromFormat.toJSDate();
      }

      // Additional formats can be added here as needed

      return undefined;
    }

    return undefined;
  }

  static toDateFormattedString(
    input: string | Date | null | undefined,
    format: string
  ): string {
    {
      const date = DateUtils.toJSDate(input);
      if (date === undefined) {
        return '';
      }
      return DateTime.fromJSDate(date).setLocale('de').toFormat(format);
    }
  }

  static getCalendarWeek(
    input: string | Date | null | undefined,
    withYear: boolean = false
  ) {
    const date = DateUtils.toJSDate(input);
    if (date === undefined) {
      return undefined;
    }
    const res = DateTime.fromJSDate(date)
      .toUTC()
      .setZone('Europe/Berlin')
      .setLocale('de');
    if (withYear) {
      return `${res.weekNumber}/${res.weekYear}`;
    }
    return res.weekNumber;
  }

  static getStartDate(
    input: string | Date | null | undefined
  ): Date | undefined {
    const date = DateUtils.toJSDate(input);
    if (date === undefined) {
      return undefined;
    }

    return DateTime.fromJSDate(date)
      .toUTC()
      .setZone('Europe/Berlin')
      .startOf('day')
      .toJSDate();
  }

  static getEndDate(input: string | Date | null | undefined): Date | undefined {
    const date = DateUtils.toJSDate(input);
    if (date === undefined) {
      return undefined;
    }

    return DateTime.fromJSDate(date)
      .toUTC()
      .setZone('Europe/Berlin')
      .endOf('day')
      .set({ millisecond: 0 })
      .toJSDate();
  }
}
